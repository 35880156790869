import { AnalyticsPlugin } from "analytics"
import { Config, EventCallback } from "@/lib/analytics/analytics-types"
import { datadogRum } from "@datadog/browser-rum"
import { buildAnalyticsCallback, buildId } from "@/lib/analytics/helpers"

function allConfigurationsSet(): boolean{
  return !!process.env.VUE_APP_DATADOG_APPLICATION_ID  &&
    !!process.env.VUE_APP_DATADOG_CLIENT_TOKEN &&
    !!process.env.VUE_APP_DATADOG_SERVICE
}

let isLoaded = false

const impl = {
  initialize: () => {
    datadogRum.init({
      applicationId: process.env.VUE_APP_DATADOG_APPLICATION_ID || "",
      clientToken: process.env.VUE_APP_DATADOG_CLIENT_TOKEN || "",
      site: "datadoghq.com",
      service: process.env.VUE_APP_DATADOG_SERVICE,
      version: `${process.env.GIT_COMMIT}-${process.env.GIT_COMMIT_OVERLAYS}`,
      env: process.env.VUE_APP_ENVIRONMENT,
      sessionSampleRate: 100,
      trackUserInteractions: true, //The trackInteractions initialization parameter enables the collection of user clicks in your application. Sensitive and private data contained on your pages may be included to identify the elements interacted with.
      trackResources: true,
      trackFrustrations: true,
      beforeSend: (event) => {
        // Ignore error from genesys
        if (event.type === "error" && event.error.message.includes("Broadcast channel instance already opened")) {
          return false
        }
      }
    })
    isLoaded = true
  },
  identify: buildAnalyticsCallback(({ payload }: EventCallback) => {
    datadogRum.setGlobalContextProperty("customer.id", buildId(payload))
    datadogRum.setGlobalContextProperty("customer.anonymous_id", payload.anonymousId)
    datadogRum.setGlobalContextProperty("customer.user_id", payload.userId)
  }),
  track: buildAnalyticsCallback(({ payload }: EventCallback) => {
    const { error, ...otherProps } = payload.properties
    if(error){
      datadogRum.addError(error, otherProps as Record<string, unknown>)
    }
    else {
      datadogRum.addAction(payload.event, payload.properties as Record<string, unknown>)
    }
  }),
  loaded:() => isLoaded
}

export default function buildDatadogPlugin(config: Config): AnalyticsPlugin & typeof impl {
  if(config.enabled && !allConfigurationsSet()){
    console.error("datadog not configured")
    config.enabled = false
  }
  return {
    name: "datadog",
    config: { ...config },
    ...impl
  }
}

